.aboutCard-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: white;
  padding-bottom: 80px;
}

.aboutCard-row-container {
  margin-top: 30px;
}

.aboutCard-text-container {
  position: relative;
  margin-top: 60px;
  width: 60%;
}

.aboutCard-title {
  font-family: 'PermanentMarker-Regular';
  text-align: center;
}

.aboutCard-left-arrow {
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}

.aboutCard-img-container {
  width: 70px;
  height: 70px;
  background-color: rgb(214, 214, 214);
  border-radius: 45px;
  padding: 10px;
  position: absolute;
  top: -95px;
  border: 2px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 600px) {
  .aboutCard-text-container {
    margin-top: 0px;
    width: 85%;
  }
  .aboutCard-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .aboutCard-img-container {
    width: 70px;
    height: 70px;
    background-color: rgb(214, 214, 214);
    border-radius: 45px;
    padding: 10px;
    position: absolute;
    top: -55px;
  }
}

@media only screen and (max-width: 500px) {
  .aboutCard-img-container {
    width: 60px;
    height: 60px;
    background-color: rgb(214, 214, 214);
    border-radius: 30px;
    padding: 5px;
    position: absolute;
    top: -50px;
  }
}