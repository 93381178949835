.menuCard-container {
  padding: 10px 20px 10px 20px !important;
  color: white;
}

.menuCard-title-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuCard-title {
  font-family: 'PatrickHandSC-Regulars';
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 0;
  margin-right: 7px;
}

.menuCard-price {
  font-family: 'PatrickHandSC-Regulars';
  font-size: 25px;
  margin-bottom: 0;
  margin-left: 7px;
}

.menuCard-ingredients {
  padding-right: 150px;
}

.dots {
  flex: 1;
  height: 20px;
  align-items: flex-end;
  border-bottom: 1px dashed rgb(158, 158, 158);
}

@media only screen and (max-width: 600px) {
  .menuCard-ingredients {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .menuCard-title {
    font-family: 'PatrickHandSC-Regulars';
    text-transform: uppercase;
    font-size: 23px;
    margin-bottom: 0;
    margin-right: 7px;
  }
  
  .menuCard-price {
    font-family: 'PatrickHandSC-Regulars';
    font-size: 23px;
    margin-bottom: 0;
    margin-left: 7px;
  }
}