.lunchMenuContainer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
  border-radius: 5px;
  padding: 15px;
}

.lunchMenuContainer-menuCard {
  color: white;
  width: 100%;
}

.lunchMenuContainer-menuTitle {
  font-family: 'PermanentMarker-Regular';
  color: white;
}

@media only screen and (max-width: 769px) {
  .lunchMenuContainer-menuCard{
    width: 100%;
  }
  .lunchMenuContainer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
    color: white;
    border-radius: 5px;
    padding: 15px;
  }
}