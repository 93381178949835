.homePage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.homePage-backgroundImage-container {
  background-image: url('../../images/sakura-background.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

/* Prevents center-logo from showing when in big screen mode */
.homePage-hanami-logo {
  width: 0;
  height: 0;
}

.homePage-innerContainer {
  display: flex;
  align-items: center;
}

.homePage-scroll-text {
  font-family: 'PermanentMarker-Regular';
  font-size: 30px;
  margin-right: 15px;
}

.homePage-social-media-container {
  position: absolute;
  bottom: 40px;
  right: 70px;
}

.homePage-instagram-logo {
  width: 70px;
  height: 70px;
  margin-right: 40px;
}

.homePage-facebook-logo {
  width: 70px;
  height: 70px;
}

.homePage-scrollButton-container {
  position: absolute;
  bottom: 32px;
  left: 70px;
}

.homePage-down-arrow {
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 850px) {
  .homePage-scroll-text {
    font-size: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .homePage-hanami-logo-container {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homePage-hanami-logo {
    width: 200px;
    height: 200px;
    position:absolute;
    top: 35%;
  }
}

@media only screen and (max-width: 500px) {
  .homePage-social-media-container {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  .homePage-instagram-logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .homePage-facebook-logo {
    width: 50px;
    height: 50px;
  }
  .homePage-scrollButton-container {
    position: absolute;
    bottom: 32px;
    left: 30px;
  }
  .homePage-down-arrow {
    width: 50px;
    height: 50px;
  }
}
