.contactPage-backgroundImage-container {
  background-image: url('../../images/sakura-background.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.contactPage-contactCard-container {
  display: flex;
  justify-content: center;
  margin-top: 50vh;
}

@media only screen and (max-width: 600px) {
  .contactPage-backgroundImage-container {
    background-image: url('../../images/sakura-background.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    width: 100vw;
  }
}