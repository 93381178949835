.menu-sheet-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgb(28, 33, 33);
}

.menu-sheet-inner-container {
  width: 80%;
  padding: 0 20px 80px 20px;
  background-color: rgb(28, 33, 33);
}

.menu-sheet-extra-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  border-radius: 10px;
  margin-bottom: 30px;
}

.menu-sheet-extra-info-student-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  border-radius: 10px;
  margin-bottom: 50px;
}

.menu-sheet-extra-info {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: rgb(255, 255, 255);
  margin-bottom: 0;
  padding: 10px 5px 10px 5px;
  text-align: center;
}

.vertical-distance {
  height: 100px;
}

.menuCardContainer-nigiri-background {
  background-image: url('../../images/nigiri.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 55%;
}

.menuCardContainer-sushi-background {
  background-image: url('../../images/sushi.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-maki-background {
  background-image: url('../../images/maki.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-special-maki-background {
  background-image: url('../../images/special-maki.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-sashimi-background {
  background-image: url('../../images/sashimi.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 60%;
}

.menuCardContainer-flambera-background {
  background-image: url('../../images/flambera.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-poke-bowls-background {
  background-image: url('../../images/poke-bowl.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

/* .menuCardContainer-family-background {
  background-image: url('../../images/family.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
} */

.menuCardContainer-flambe-background {
  background-image: url('../../images/flambe.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-childrens-menu-background {
  background-image: url('../../images/childrens-menu.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-shrimp-chips-background {
  background-image: url('../../images/shrimp-chips.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 50%;
}

.menuCardContainer-drinks-background {
  background-image: url('../../images/drinks.jpg');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 400px;
  width: 100vw;
  margin-left: -15%;
  background-position: 0px 25%;
}

.bubble-tea-div {
  margin-top: 135px;
}


@media only screen and (max-width: 600px) {
  .menu-sheet-inner-container {
    width: 95%;
    padding: 0 5px 80px 5px;
    background-color: rgb(28, 33, 33);
  }
  .vertical-distance {
    height: 0px;
  }

  .menuCardContainer-nigiri-background {
    background-image: url('../../images/nigiri.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 90%;
  }
  
  .menuCardContainer-sushi-background {
    background-image: url('../../images/sushi.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 80%;
  }
  
  .menuCardContainer-maki-background {
    background-image: url('../../images/maki.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 80%;
  }
  
  .menuCardContainer-special-maki-background {
    background-image: url('../../images/special-maki.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 100%;
  }

  .menuCardContainer-sashimi-background {
    background-image: url('../../images/sashimi.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 80%;
  }
  
  .menuCardContainer-flambera-background {
    background-image: url('../../images/flambera.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 0px;
  }

  .menuCardContainer-poke-bowls-background {
    background-image: url('../../images/poke-bowl.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 50%;
  }
  
 /*  .menuCardContainer-family-background {
    background-image: url('../../images/family.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 0px;
  } */
  
  .menuCardContainer-flambe-background {
    background-image: url('../../images/flambe.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 0px;
  }
  
  .menuCardContainer-childrens-menu-background {
    background-image: url('../../images/childrens-menu.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px ;
  }
  
  .menuCardContainer-shrimp-chips-background {
    background-image: url('../../images/shrimp-chips.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 50%;
  }
  
  .menuCardContainer-drinks-background {
    background-image: url('../../images/drinks.jpg');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 200px;
    width: 100vw;
    margin-left: -5%;
    background-position: 0px 20%;
  }

  .bubble-tea-div {
    margin-top: 50px;
  }  
}

