.contactCard-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: white;
  padding-bottom: 80px;
}

.contactCard-row-container {
  margin-top: 30px;
}

.contactCard-text-container {
  position: relative;
  margin-top: 60px;
  width: 60%;
}

.contactCard-title {
  font-family: 'PermanentMarker-Regular';
  text-align: center;
}

.contactCard-left-arrow {
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}

.contactCard-img-container {
  width: 70px;
  height: 70px;
  background-color: rgb(214, 214, 214);
  border-radius: 45px;
  padding: 10px;
  position: absolute;
  top: -95px;
  border: 2px solid rgb(255, 255, 255);
}

.contactCard-contact-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.contactCard-mail-text {
  margin-top: 15px;
}

.contactCard-phone-a-text, .contactCard-mail-a-text {
  margin-left: 3px;
}

@media only screen and (max-width: 600px) {
  .contactCard-text-container {
    margin-top: 20px;
    width: 75%;
  }
  .contactCard-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .contactCard-img-container {
    width: 70px;
    height: 70px;
    background-color: rgb(214, 214, 214);
    border-radius: 45px;
    padding: 10px;
    position: absolute;
    top: -75px;
  }
}

@media only screen and (max-width: 500px) {
  .contactCard-img-container {
    width: 60px;
    height: 60px;
    background-color: rgb(214, 214, 214);
    border-radius: 30px;
    padding: 5px;
    position: absolute;
    top: -70px;
  }
  .contactCard-phone-text, .contactCard-mail-text {
    font-size: 18px;
  }
}