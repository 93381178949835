
.menuCardContainer-container {
  margin-top: -140px;
  background-color: rgba(28, 33, 33, 0.9);
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
}

.menuTitle {
  font-family: 'PermanentMarker-Regular';
  color: white;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.menuExtraInfo {
  color: white;
  width: 100%;
  text-align: center;
  padding: 0 250px 0 250px;
}

@media only screen and (max-width: 992px) {
  .menuExtraInfo {
    color: white;
    width: 100%;
    text-align: center;
    padding: 0 50px 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .menuCardContainer-container {
    margin-top: -70px;
    background-color: rgba(28, 33, 33, 0.9);
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .menuTitle {
    font-size: 30px !important;
  }
  .menuExtraInfo {
    color: white;
    width: 100%;
    text-align: center;
    padding: 0 10px 0 10px;
    font-size: 15px;
  }
}