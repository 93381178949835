.nav-links-menu {
  margin-right: 50px;
  font-size: 20px;
  margin-top: 10px;
}

.nav-logo-text {
  font-family: 'PermanentMarker-Regular';
  font-size: 80px !important;
  margin-left: 20px;
}

.nav-link-text {
  font-family: 'PermanentMarker-Regular';
  font-size: 40px !important;
  margin-left: 20px;
  color: rgb(39, 39, 39) !important;
}

.navbar-hanami-logo {
  width: 150px;
  height: 150px;
}

@media only screen and (max-width: 1400px) {
  .nav-logo-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 60px !important;
    margin-left: 20px;
  }
  .nav-link-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 40px !important;
    margin-left: 20px;
    color: rgb(39, 39, 39) !important;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-logo-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 50px !important;
    margin-left: 20px;
  }
  .nav-link-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 30px !important;
    margin-left: 20px;
    color: rgb(39, 39, 39) !important;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar-collapse {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: rgb(136, 136, 136) solid 1px;
    margin-top: 5px;
    margin-left: 480px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-hanami-logo {
    width: 0;
    height: 0;
  }
  .navbar-collapse {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: rgb(136, 136, 136) solid 1px;
    margin-top: 5px;
    margin-left: 480px;
  }
}

@media only screen and (max-width: 720px) {
  .nav-link-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 30px !important;
    margin-left: 20px;
    color: rgb(39, 39, 39) !important;
    text-shadow: 2px 2px 10px rgb(255, 255, 255);
  }
  .navbar-collapse {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: rgb(136, 136, 136) solid 1px;
    margin-top: 5px;
    margin-left: 280px;
  }
}

@media only screen and (max-width: 650px) {
  .nav-logo-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 43px !important;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 550px) {
  .nav-logo-text {
    font-family: 'PermanentMarker-Regular';
    font-size: 30px !important;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .navbar-collapse {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: rgb(136, 136, 136) solid 1px;
    margin-top: 5px;
    margin-left: 180px;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-collapse {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: rgb(136, 136, 136) solid 1px;
    margin-top: 5px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 385px) {
  .navbar {
    display: flex;
    justify-content: start !important;
  }
}