.footer-row-container {
  border-top: solid 1px white;
  width: 100%;
  background-color: rgb(28, 33, 33);
  padding: 40px 10px 40px 10px;
  margin: 0 !important;
}

.footer-hanami-map {
  background-image: url('../../images/hanami-map.png');
  background-repeat: 'no-repeat';
  background-size: cover;
  height: 260px;
  width: 260px;
  border-radius: 130px;
}

.footer-first-col-header1 {
  font-size: 30px;
  border-bottom: 1px solid white;
  margin-bottom: 5px;
}

.footer-first-col-days {
  font-size: 20px;
  margin-bottom: 0;
}

.footer-first-col-time {
  font-size: 17px;
}

.footer-first-col-city {
  font-size: 30px;
  margin-bottom: 0;
}

.footer-first-col-adress {
  font-size: 17px;
  margin-bottom: 5px;
}

.footer-social-media-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.footer-instagram-logo {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  color: white;
  background-color: white;
  border-radius: 19px;
}

.footer-facebook-logo {
  width: 60px;
  height: 60px;
  color: white;
  background-color: white;
  border-radius: 10px;
}

.footer-first-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.footer-second-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.footer-third-col-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-third-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

@media only screen and (max-width: 995px) {
  .footer-hanami-map {
    background-image: url('../../images/hanami-map.png');
    background-repeat: 'no-repeat';
    background-size: cover;
    height: 260px;
    width: 260px;
    border-radius: 130px;
    margin: 40px 0 50px 0;
  }
}